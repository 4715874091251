import { ArrowLeft } from '@mui/icons-material'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import React, { useState } from 'react'
import StateUpdators from '../../lib/StateUpdators'
import { useParams } from 'react-router-dom'
import Utils from '../../lib/Utils'

/**
 * JumpToLessonView. Allows jumping to a given lesson the lesson page
 * @returns 
 */
const JumpToLessonView = () => {
    console.log('{JumpToLessonView}')
    const [title, setTitle] = useState('Hide')
    const [showState, setShowState] = useState(false)
    const [lessons, setJumpToLessons] = useState([])
    const [input, setJumpToLesson] = useState('')
    StateUpdators.setJumpToLessons = setJumpToLessons
    let token = useParams().token
    if(token) {
        token = JSON.parse(Utils.base64Decode(token))
    }
    return (
        <>
        {
            showState ? 
            <div className='ksks-psmcjks rounded-right animate__animated animate__fadeIn shadow grid'>
                <div>
                    <FormControl fullWidth>
                        <InputLabel id='jump-to-less-select-label'>Go To:</InputLabel>
                        <Select
                            labelId='jump-to-less-select-label'
                            id='jump-to-less-select-label'
                            value={input?JSON.stringify(input):input}
                            label='Go To:'
                            onChange={(e) => {
                                const _input = JSON.parse(e.target.value)
                                setJumpToLesson({
                                    ...input,
                                    ..._input
                                })
                                setTimeout(() => {
                                    token.lessonId = _input.lessonId
                                    Utils.goToLesson({...token})
                                },Utils._timeouts.jumpToLesson[0])
                            }}
                        >
                            {
                                lessons.map((lesson,key) => {
                                    return (
                                        <MenuItem key={key} value={JSON.stringify(lesson)}>{lesson.number}. {lesson.title}</MenuItem>
                                    )
                                })
                            }
                        </Select>
                    </FormControl>
                </div>
                <div title={title} className='content-centre ptr' onClick={() => {
                    setShowState(false)
                    setTitle('Show')
                }}>
                    <ArrowLeft sx={{fontSize: 40}} />
                </div>
            </div> :
            <div title={title} className='ptr kss-lelks rounded-right shadow animate__animated animate__fadeIn' onClick={() => {
                setShowState(true)
                setTitle('Hide')
            }}>
            </div>
        }
        </>
    )
}

export default JumpToLessonView