/**
 * Base names for the app
 */
const BaseName = {
    name: 'ns'
}

//Overrides
//BaseName.name = 'ns_demo'
BaseName.name = 'lms'
//BaseName.name = 'ns'
//BaseName.name = 'pay_test'
//BaseName.name = 'ai_demo'

export default BaseName