import React, { useEffect, useRef, useState } from 'react';
import Utils from '../../lib/Utils';
import StateUpdators from '../../lib/StateUpdators';
import { MuiOtpInput } from 'mui-one-time-password-input';
import Separator from './Separator';
import Texts from '../../lib/Texts';
import { LinearProgress } from '@mui/material';

/**
 * RegisterNewAccountValidateOTPView
 * @param {number} challengeId The challengeId  
 * @returns 
 */
const RegisterNewAccountValidateOTPView = ({challengeId}) => {
    console.log('{RegisterNewAccountValidateOTPView}')
    const TWO_SECS = 2000
    const [pin, setPIN] = useState('')
    const [_validating, setValidating] = useState({
        doing: true,
        done: false
    })
    const [__validating, setValidatingAction] = useState(false)
    StateUpdators.setValidationPIN = setPIN
    StateUpdators.setValidating = setValidating
    StateUpdators.setValidatingAction = setValidatingAction
    const handleOnChange = (newValue) => {
        setPIN(newValue)
    }
    //const validating = useRef(null)
    let timer = useRef(null)
    const handleOnComplete = (value) => {
        if(!__validating) {
            setValidating({
                doing: false,
                done: false,
            })
            setValidatingAction(true)
            timer = setTimeout(() => {
                Utils.validateRegisterAccountCode({challengeId: challengeId, otpCode: parseInt(value)})
            }, TWO_SECS)
        } else {
            if(_validating.retry??false) {
                setValidating({
                    doing: false,
                    done: false,
                })
                setValidatingAction(true)
                timer = setTimeout(() => {
                    Utils.validateRegisterAccountCode({challengeId: challengeId, otpCode: parseInt(value)})
                }, TWO_SECS)
            }
        }
    }
    const matchIsNumeric = (text) => {
        const isNumber = typeof text === 'number'
        return (isNumber || (text !== '')) && !isNaN(Number(text))
    }
    // eslint-disable-next-line
    const validateChar = (value,index) => {
        return matchIsNumeric(value)
    }
    const [V,setValidationResults] = useState({
        C: () => (<></>)
    })
    StateUpdators.setValidationResults = setValidationResults
    StateUpdators.setValidating = setValidating
    StateUpdators.setPIN = setPIN
    //console.log('pin=',pin)
    useEffect(() => {
        StateUpdators.setHeader('Let\'s activate your account')
        const __timer = timer.current
        return () => clearTimeout(__timer)
    });
    return (
        <>
        <div className='hsgsg-os box-b animate__animated animate__fadeInRight w-90 white-bkg shadow rounded centred'>
        {
            _validating.doing ? 
            <>
                <p className='black-text jss-lsls'>Enter the 6-digit code you received from us:</p>
                <span className='ndhsh-bs-sure black-text'>{Texts.bSure}</span>
                <Separator style={{height: '20px'}} />
                <MuiOtpInput
                    autoFocus
                    TextFieldsProps={{ placeholder: '-' }}
                    value={pin} 
                    length={6}
                    onChange={handleOnChange} 
                    onComplete={handleOnComplete}
                    validateChar={validateChar}
                />
            </> :
            _validating.done ?
                <V.C /> :
                <div className='text-centre ksjsj rounded'>
                    <LinearProgress />
                    <p>Activating your account...</p>
                </div>
        }
        </div>
        </>
    )
}

export default RegisterNewAccountValidateOTPView