import React, { Suspense } from 'react'

//current defined integrations
const Integrations = {
    eMobile: {
        title: 'eMobile Integration',
        /**
         * The Integration handler
         * @returns 
         */
        Handler: () => {
            console.log('{Integrations.eMobile.Handler}')
            const View = React.lazy(() => import('../views/tools/integrations/EMobileIntegration'))
            return (
                <>
                <Suspense>
                    <View />
                </Suspense>
                </>
            )
        }
    }
}

export default Integrations