import React, { useEffect, useRef } from 'react'
import Assets from '../../../lib/Assets'
import Utils from '../../../lib/Utils'
import PopupHandleTitle from '../PopupHandleTitle'
import NoLessonResources from '../viewable_bits/NoLessonResources'

/**
 * 
 * @param {object} props The props object
 * @returns 
 */
const Resources = ({lesson}) => {
    console.log('{Resources}')
    //console.log('{Resources} lesson=',lesson)
    const resources = lesson.lesson.resources ?? false
    const weblinks = lesson.lesson.weblinks ?? false
    const images = lesson.lesson.images ?? false
    const videos = lesson.lesson.videos ?? false
    const audios = lesson.lesson.audios ?? false
    const _lesson = useRef({lessonId: lesson.lesson.lessonId, courseId: lesson.lesson.courseId})
    const tooManyResources = (resources.length+weblinks.length+images.length+videos.length+audios.length) > 8 ? true:false
    useEffect(() => {
        Utils.addAnalytics({
            page: 'Resources',
            url: window.location.href,
            title: 'NS.Resources',
            lessonId: _lesson.current.lessonId,
            courseId: _lesson.current.courseId
        });
    },[]);
    return (
        <>
        <div className='hfgfgf-nvfg-task- w-80 h-centred rounded-small animate__animated animate__fadeIn'>
            <h4 className='jdhd-hfg-i' dangerouslySetInnerHTML={{__html: `${lesson.curriculumTitle} > ${lesson.courseTitle} > ${lesson.subCourseTitle?` ${lesson.subCourseTitle} >`:''} ${lesson.lesson.number}. ${lesson.lesson.lessonTitle}`}}></h4>
            <PopupHandleTitle
                title={'Resources'}
                onLeftIconClick={() => {
                    Utils.closeLessonTaskPageModal();
                }}
                onRightIconClick={() => {
                    //Utils.miniMize({title: 'Instructions'});
                }}
                showMinimizeButton={true}
            />
        </div>
        <div className={`animate__animated animate__fadeIn vnvhfhfjdgf w-80 h-centred ${tooManyResources?'many-hdgdg-temps':''}`}>
            {
                resources && 
                <>
                {
                    resources.length > 0 &&
                    <>
                    {
                        resources.map((resource, i) => {
                            let fileType
                            fileType = resource.type
                            resource.name = resource.name.replace(`.${fileType.toLocaleLowerCase()}`,'');
                            //console.log('resource.name=',resource.name, 'fileType=',fileType)
                            return (
                                <div key={i} title={resource.name} className='the-docs-nfhgfhfgdgh h-centred' onClick={() => {
                                    Utils.addAnalytics({
                                        page: 'Lesson.Resources',
                                        url: window.location.href,
                                        title: `NS.Lesson.Resources.Access`,
                                        fileType: fileType,
                                        action: `AccessResource: ${resource.name}`,
                                        lessonId: _lesson.current.lessonId,
                                        courseId: _lesson.current.courseId
                                    });
                                    window.open(resource.path);
                                }}>
                                    <div className='doc-nfhfh shadow rounded pointer'>
                                        <div className='the-icon-nvnfh'>
                                            <img className='icon-doc-mbsidhf h-centred' src={Assets.fileTyeps[fileType]} alt={resource.name} />
                                        </div>
                                        <div className='bcgdgd-mjfhf-mshs h-centred'>
                                            <h3 className={`course-text-color-${lesson.color}`}>
                                                {resource.name}
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                    </>
                }
                </>
            }
            {
                weblinks &&
                <>
                {
                    weblinks.length > 0 &&
                    <>
                    {
                        weblinks.map((weblink, i) => {
                            //console.log('weblink',weblink);
                            weblink.name = weblink.name.replace(`.${weblink.type.toLocaleLowerCase()}`,'');
                            return (
                                <div key={i} title={weblink.name} className='the-docs-nfhgfhfgdgh h-centred' onClick={() => {
                                    Utils.addAnalytics({
                                        page: 'Lesson.Resources',
                                        url: window.location.href,
                                        title: `NS.Lesson.Resources.Access`,
                                        fileType: 'weblink',
                                        action: `AccessResource: ${weblink.name}`,
                                        lessonId: _lesson.current.lessonId,
                                        courseId: _lesson.current.courseId
                                    });
                                    window.open(weblink.url);
                                }}>
                                    <div className='doc-nfhfh shadow rounded pointer'>
                                        <div className='the-icon-nvnfh'>
                                            <img className='icon-doc-mbsidhf h-centred' src={Assets.fileTyeps[weblink.type]} alt={weblink.name} />
                                        </div>
                                        <div className='bcgdgd-mjfhf-mshs h-centred'>
                                            <h3 className={`course-text-color-${lesson.color}`}>{weblink.name}</h3>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                    </>
                }
                </>
            }
            {
                images &&
                <>
                {
                    images.length > 0 &&
                    <>
                    {
                        images.map((image, i) => {
                            image.name = image.name.replace(`.${image.type.toLocaleLowerCase()}`,'');
                            return (
                                <div key={i} title={image.name} className='the-docs-nfhgfhfgdgh h-centred' onClick={() => {
                                    Utils.addAnalytics({
                                        page: 'Lesson.Resources',
                                        url: window.location.href,
                                        title: `NS.Lesson.Resources.Access`,
                                        fileType: 'image',
                                        action: `AccessResource: ${image.name}`,
                                        lessonId: _lesson.current.lessonId,
                                        courseId: _lesson.current.courseId
                                    });
                                    window.open(image.url);
                                }}>
                                    <div className='doc-nfhfh shadow rounded pointer'>
                                        <div className='the-icon-nvnfh'>
                                            <img className='icon-doc-mbsidhf h-centred' src={Assets.fileTyeps[image.type]} alt={image.name} />
                                        </div>
                                        <div className='bcgdgd-mjfhf-mshs h-centred'>
                                            <h3 className={`course-text-color-${lesson.color}`}>{image.name}</h3>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                    </>
                }
                </>
            }
            {
                videos &&
                <>
                {
                    videos.length > 0 &&
                    <>
                    {
                        videos.map((video, i) => {
                            video.name = video.name.replace(`.${video.type.toLocaleLowerCase()}`,'');
                            return (
                                <div key={i} title={video.name} className='the-docs-nfhgfhfgdgh h-centred' onClick={() => {
                                    Utils.addAnalytics({
                                        page: 'Lesson.Resources',
                                        url: window.location.href,
                                        title: `NS.Lesson.Resources.Access`,
                                        fileType: 'video',
                                        action: `AccessResource: ${video.name}`,
                                        lessonId: _lesson.current.lessonId,
                                        courseId: _lesson.current.courseId
                                    });
                                    window.open(video.url);
                                }}>
                                    <div className='doc-nfhfh shadow rounded pointer'>
                                        <div className='the-icon-nvnfh'>
                                            <img className='icon-doc-mbsidhf h-centred' src={Assets.fileTyeps[video.type]} alt={video.name} />
                                        </div>
                                        <div className='bcgdgd-mjfhf-mshs h-centred'>
                                            <h3 className={`course-text-color-${lesson.color}`}>{video.name}</h3>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                    </>
                }
                </>
            }
            {
                audios &&
                <>
                {
                    audios.length > 0 &&
                    <>
                    {
                        audios.map((audio, i) => {
                            audio.name = audio.name.replace(`.${audio.type.toLocaleLowerCase()}`,'');
                            return (
                                <div key={i} title={audio.name} className='the-docs-nfhgfhfgdgh h-centred' onClick={() => {
                                    Utils.addAnalytics({
                                        page: 'Lesson.Resources',
                                        url: window.location.href,
                                        title: `NS.Lesson.Resources.Access`,
                                        fileType: 'audio',
                                        action: `AccessResource: ${audio.name}`,
                                        lessonId: _lesson.current.lessonId,
                                        courseId: _lesson.current.courseId
                                    });
                                    window.open(audio.url);
                                }}>
                                    <div className='doc-nfhfh shadow rounded pointer'>
                                        <div className='the-icon-nvnfh'>
                                            <img className='icon-doc-mbsidhf h-centred' src={Assets.fileTyeps[audio.type]} alt={audio.name} />
                                        </div>
                                        <div className='bcgdgd-mjfhf-mshs h-centred'>
                                            <h3 className={`course-text-color-${lesson.color}`}>{audio.name}</h3>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                    </>
                }
                </>
            }
            {
                (resources.length < 1 && weblinks.length < 1 && images.length < 1 && videos.length < 1 && audios.length < 1) &&
                <NoLessonResources />
            }
        </div>
        </>
    )
}

export default Resources;