import React, { useEffect } from 'react';
import Utils from '../../lib/Utils';
import Assets from '../../lib/Assets';
import OldEducatorDasboardLink from './OldEducatorDasboardLink';

/**
 * Educator dashboard left menu
 * @returns 
 */
const EducatorDashboardLeftMenu = () => {
    console.log('{EducatorDashboardLeftMenu}');
    const curriculum = Utils.getCachedCurriculumInfo();
    const Menu = !curriculum ? Menus.DSC : (Menus[curriculum.extra.shortName]??Menus.DSC);
    useEffect(() => {
        Utils.setEducatorDashboardActiveAction({itemId: 'first-item-jhsgsg', header: 'Welcome', name: 'Welcome'});
    },[]);
    return (
        <>
        <Menu />
        </>
    )
}

export default EducatorDashboardLeftMenu;


/**
 * The Educator Dashboard menus items.
 * Each curriculum will have its menus actions defined in this module
 */
const Menus = {
    /**
     * Default menu
     * @returns 
     */
    Default: () => (<></>),
    /**
     * The Digitial Skills curriculum menus items
     * @returns 
     */
    DSC: () => {
        console.log('{Menus.DSC}');
        return (
            <>
            <div className='dashkfhf-kfjfnmune brd-right-grey'>
                {
                    MenuItems.DSC.map((item,index) => {
                        return (
                            <div key={index} id={item.id} className='nds-kfjdhd ptr' title={item.title} onClick={(e) => {
                                Utils.addAnalytics({
                                    page: 'EducatorDashboard',
                                    url: window.location.href,
                                    title: 'NS.EducatorDashboard.Action',
                                    item: item
                                });
                                Utils.setEducatorDashboardActiveAction({
                                    itemId: e.currentTarget.id, 
                                    header: item.header,
                                    name: item.name,
                                    extraRenders: item.extraRenders??false
                                });
                            }}>
                                <img alt={item.alt} src={item.icon} className='jdhddf ptr' />
                            </div>
                        )
                    })
                }
            </div>
            </>
        )
    }
}

/**
 * Menu Items
 */
const MenuItems = {
    DSC: [
        {
            id: 'first-item-jhsgsg',
            title: 'Welcome to your educator dashboard',
            header: 'Welcome',
            icon: Assets.icons.educatorDashboard.welcome,
            alt: 'Welcome',
            name: 'Welcome'
        },
        {
            id: 'hsjs-ieuiehd',
            title: 'Watch To Get Started',
            header: 'Watch To Get Started',
            icon: Assets.icons.educatorDashboard.readToGetStarted,
            alt: 'Watch To Get Started',
            name: 'GetStarted'
        },
        {
            id: 'hsjs-i-ksjs',
            title: 'Assessments & Feedback',
            header: 'Assessments & Feedback',
            icon: Assets.icons.educatorDashboard.assessmentsAndFeedback,
            alt: 'Assessments & Feedback',
            name: 'AssessmentsAndFeedback'
        },
        {
            id: 'hsjs-sl-eusxf',
            title: 'Planning & Organizing',
            header: 'Planning & Organizing',
            icon: Assets.icons.educatorDashboard.planAndOrganize,
            alt: 'Planning & Organizing',
            name: 'PlanAndOrganize'
        },
        {
            id: 'hsjs-h-lsjds',
            title: 'Digital PINS',
            header: 'Digital PINS',
            icon: Assets.icons.educatorDashboard.digitalPINS,
            alt: 'Digital PINS',
            name: 'DigitalPINS'
        },
        {
            id: 'hsjs-swodls',
            title: 'Search Lessons',
            header: 'Search Lessons',
            icon: Assets.icons.educatorDashboard.searchLessons,
            alt: 'Search Lessons',
            name: 'SearchLessons'
        },
        {
            id: 'hsjs-gs-lws',
            title: 'For the Classroom',
            header: 'For the Classroom',
            icon: Assets.icons.educatorDashboard.forTheClassRoom,
            alt: 'For the Classroom',
            name: 'ForTheClassroom'
        },
        {
            id: 'hsjs-a-l-wxa',
            title: 'Curriculum Overviews',
            header: 'Curriculum Overviews',
            icon: Assets.icons.educatorDashboard.curriculumOverviews,
            alt: 'Curriculum Overviews',
            name: 'CurriculumOverviews',
        },
        {
            id: 'hsjs-l-sklwxms',
            title: 'News & Updates',
            header: 'News & Updates',
            icon: Assets.icons.educatorDashboard.newsAndUpdates,
            alt: 'News & Updates',
            name: 'NewsAndUpdates',
            extraRenders: () => <OldEducatorDasboardLink functionality='NewsAndUpdates' />
        },
        {
            id: 'hsjs-a-l-wxa-j',
            title: 'Progress Report',
            header: 'Generate Student Progress Reports',
            icon: Assets.icons.educatorDashboard.progressReports,
            alt: 'Progress Report',
            name: 'ProgressReport',
        },
    ]
};