import React, { useEffect, useState } from 'react';
import Utils from '../../lib/Utils';
import StateUpdators from '../../lib/StateUpdators';
import { DataGrid } from '@mui/x-data-grid';
import Assets from '../../lib/Assets';
import { ActionsOnASingleStudent, ActionsOnMultipleStudents } from './TableActions';
import DataGridHeaders from '../../lib/DataGridHeaders';

/**
 * Students view
 * @returns 
 */
const Students = () => {
    console.log('{Students}');
    const [students, setStudentsData] = useState({
        data: []
    })
    StateUpdators.setStudentsData = setStudentsData;
    const [rowSelectionModel2, setRowSelectionModel2] = useState([]);
    useEffect(() => {
        Utils.fetchInstitutionStudents();
    },[]);
    return (
        <>
        <div className='hddg-info-hdhdg w-80 centered box-b rounded shadow mnhd0jdh animate__animated animate__fadeIn'>
            <p>Registered Students</p>
            {
                Array.isArray(students.data) && students.data.length > 0 ? 
                <>
                <div className='jhdg-jfhfh w-90 centred bdr-bl rounded box-b'>
                    <div className='hfgdg w-90 rounded-4px box-b shadow centred'>
                        <DataGrid 
                            getRowId={row => row.studentId}
                            columns={DataGridHeaders.students}
                            rows={students.data}
                            initialState={{
                                pagination: {
                                paginationModel: {
                                    pageSize: 50,
                                },
                                },
                            }}
                            pageSizeOptions={[50, 100]}
                            checkboxSelection
                            disableRowSelectionOnClick
                            onRowSelectionModelChange={(newRowSelectionModel) => {
                                setRowSelectionModel2(newRowSelectionModel);
                            }}
                        />
                        {
                            rowSelectionModel2.length !== 0 &&
                            <>
                            {
                                rowSelectionModel2.length > 1 ?
                                <ActionsOnMultipleStudents students={students.data.filter(student => rowSelectionModel2.indexOf(student.studentId) > -1)} />
                                :
                                <ActionsOnASingleStudent students={students.data.filter(student => rowSelectionModel2[0] === student.studentId)} />
                            }
                            </>
                        }
                    </div>
                </div>
                </>
                :
                <>
                <div className='jhdg-jfhfh w-90 centred bdr-bl rounded box-b'>
                    <div className='njdhd-jfhfh w-90 box-b shadow centred'>
                        <img alt='No students' src={Assets.icons.emptyBox} className='empty-box' />
                        <p>This institution has no students yet</p>
                    </div>
                </div>
                </>
            }
        </div>
        </>
    )
}

export default Students;