import React from 'react'
import PopupHandleTitle from '../PopupHandleTitle'
import Utils from '../../../lib/Utils'

/**
 * Instructions Title
 * @returns 
 */
const InstructionsTitle = (props) => {
    console.log('{InstructionsTitle}')
    //console.log('{InstructionsTitle}: props=',props)
    return (
        <>
        <div className='hfgfgf-nvfg-task- w-80 h-centred rounded-small animate__animated animate__fadeIn'>
            <h4 className='jdhd-hfg-i' dangerouslySetInnerHTML={{__html: `${props.curriculumTitle} > ${props.courseTitle} > ${props.subCourseTitle?` ${props.subCourseTitle} >`:''} ${props.lesson.number}. ${props.lesson.lessonTitle}`}}></h4>
            <PopupHandleTitle
                title={'Instructions'}
                onLeftIconClick={() => {
                    Utils.closeLessonTaskPageModal()
                }}
                onRightIconClick={() => {
                    Utils.addAnalytics({
                        page: 'Lesson.Instructions',
                        url: window.location.href,
                        title: `NS.Lesson.Instructions.Minimize`,
                        lessonId: props.lessonId,
                        courseId: props.courseId
                    })
                    Utils.miniMize({title: 'Instructions'})
                }}
            />
        </div>
        </>
    )
}

export default InstructionsTitle