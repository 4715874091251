import React from 'react'
import Assets from '../../../lib/Assets'

/**
 * UserMessage
 * @param {object} props Th props object
 * @returns 
 */
const UserMessage  = (props) => {
    console.log('{UserMessage}')
    //console.log('{UserMessage}: props=', props)
    return (
        <>
        <div className={`jsjs-pels-user w-90 grid box-b text-by-${props.prompt.by}`}>
            <div>
                <div>
                    <span>{props.prompt.text}</span>
                </div>
            </div>
            <div>
                <img alt='User profile' className='sjs-bot-as-pp' src={Assets.icons.avatarPlaceHolder} />
            </div>
        </div>
        </>
    )
}
export default UserMessage