import React from 'react'
import HelpTip from './HelpTip'
import '../../css/need_help_round_popup.css'
import Utils from '../../lib/Utils'

/**
 * Show the Need Help Round Popup. Sometimes the information to be displayed comes from the database announcing new features.
 * @param {object} props The props object with the following properties: `title` - the title of the `HelpTip` component, `doNotDisplayHelpTip` - whether to display the `HelpTip` component or not, `_title` - the title of this component
 * @returns 
 */
const NeedHelpRoundPopup = props => {
    console.log('{NeedHelpRoundPopup}')
    const title = props.title??'Need Help'
    const DisplayHelpTip = !props.doNotDisplayHelpTip ? () => <HelpTip {...props} /> : () => (<></>)
    return (
        <div onClick={() => {
            Utils.getQuickHelpInfo({...props})
        }} className='ptr rounded shadow animate__animated animate__fadeIn dl-pemsss' title={props._title??'Click this to view help about this section'}>
            <p>{title} <DisplayHelpTip /></p>
        </div>
    )
}

export default NeedHelpRoundPopup