/**
 * Data grid column headers
 */
const DataGridHeaders = {
    //for the progressReport datagrid
    progressReport: [
        { field: 'studentId', headerName: 'Student ID', width: 100, classes: 'hshsgd-lksjsh' },
        { field: 'firstname',headerName: 'Firstname', width: 100 },
        { field: 'lastname',headerName: 'Lastname', width: 100 },
        { field: 'othernames',headerName: 'Othernames', width: 100 }
    ],
    //students view
    students: [
        { field: 'studentId', headerName: 'Student ID', width: 100, classes: 'hshsgd-lksjsh' },
        { field: 'studentNumber', headerName: 'Certification #', width: 120 },
        { field: 'firstname',headerName: 'Firstname', width: 100 },
        { field: 'lastname',headerName: 'Lastname', width: 100 },
        { field: 'othernames',headerName: 'Othernames', width: 100 },
        { field: 'grade',headerName: 'Grade/Level', width: 100 },
        {field: 'certification', headerName: 'Certification', width: 100}
    ]
}

export default DataGridHeaders