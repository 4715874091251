import React from 'react'
import Assets from '../../../lib/Assets'
import Utils from '../../../lib/Utils'
import HelpTip from '../HelpTip'
import { Button } from '@mui/material'
import Separator from '../Separator'

/**
 * SuccessGenerateStudentReport
 * @param {object} props The props objct
 * @returns 
 */
const SuccessGenerateStudentReport = props => {
    console.log('{SuccessGenerateStudentReport}')
    return (
        <div className='sjsj-peoe w-80 centred text-centre'>
            <img alt='Success' src={Assets.icons.statusOk} className='status-ok' />
            <p>The student report was successfully generated.</p>
            <div className='text-centre'>
                <Button onClick={() => {
                    Utils.goTo({url: props.reportURL, inNewWindow: true})
                }} title='Download the report' className='ptr' color='primary' variant='contained'>
                    Download the Report ({props.fileSize})
                </Button>
            </div>
            <Separator style={{height: '10px'}} />
            <div className='text-centre'>
                <p>Next steps &raquo; <HelpTip section='Student Progress Report' topic='about-howto-view-it' /></p>
            </div>
        </div>
    )
}

export default SuccessGenerateStudentReport