import { QuestionMark } from '@mui/icons-material'
import React from 'react'
import Utils from '../../lib/Utils'

/**
 * 
 * @param {object} props The `props` object containing `section` - the section of the help, `topic` - the topic of the help
 * @returns 
 */
const HelpTip = (props) => {
    console.log('{HelpTip}')
    return (
        <>
        <QuestionMark titleAccess={props.title??'Get help with this'} className='ptr' onClick={() => {
            Utils.getQuickHelpInfo({...props})
        }} />
        </>
    )
}

export default HelpTip