import React from 'react'
import Assets from '../../../lib/Assets'

/**
 * No lesson resources view
 * @returns 
 */
const NoLessonResources = (props) => {
    return (
        <div className={`no-templates ${props.className??''}`}>
            <img className={`empty-nfgdgdg ptr`} src={Assets.icons.emptyBox} alt='No templates' title='No templates for this lesson' />
            <h3>This lesson has no resources.</h3>
        </div>
    )
}

export default NoLessonResources