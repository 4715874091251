import React from 'react'
import Assets from '../../../lib/Assets'

/**
 * BotMessageError
 * @param {object} props Th props object
 * @returns 
 */
const BotMessageError  = (props) => {
    console.log('{BotMessageError}')
    return (
        <>
        <div className={`jsjs-pels w-90 grid box-b text-by-${props.response.by}`}>
            <div>
                <img alt='Bot profile' className='sjs-bot-as-pp' src={Assets.icons.aiBot} />
            </div>
            <div>
                <div className='text-centre ksks-bot-sjs box-b'>
                    <div className='error'>
                        <span>An error has occured:</span><br></br>
                        <span>{props.errMsg}</span>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}
export default BotMessageError