import React from 'react';
import Assets from '../../lib/Assets';

/**
 * The Close icon
 * @param {fn} onClose The `onClose` function to execute when the close icon is clicked
 * @param {string} className The `className` names (comma separarted list of class names)
 * @param {string} iconColor The icon color
 * @returns 
 */
const CloseIcon = ({onClose, className, iconColor}) => {
    console.log('{CloseIcon}')
    const icons = {
        white: Assets.icons.closeViewWhite,
        default: Assets.icons.closeView
    };
    return (
        <>
            <img onClick={onClose} src={icons[iconColor??'default']} alt='Dismiss' title='Dismiss' className={className?`ptr close-icon ${className}`:'ptr close-icon'} />
        </>
    )
}

export default CloseIcon;