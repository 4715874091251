import { Send } from '@mui/icons-material'
import { FormControl, InputAdornment, TextField } from '@mui/material'
import React, { useState } from 'react'
import Utils from '../../../lib/Utils'

/**
 * ChatTextInput view
 * @param {object} props The props object
 * @returns 
 */
const ChatTextInput = (props) => {
    console.log('{ChatTextInput}')
    const [msg, setMessage] = useState('')
    return (
        <div className='mdms-ksksls'>
            <FormControl fullWidth>
                <TextField
                    placeholder='Ask me anything educational'
                    margin='dense'
                    value={msg}
                    onKeyDown={(e) => {
                        if(e.code === 'Enter') {
                            Utils.sendMessageToAssistant({...props, prompt: msg})
                            setMessage('')
                        }
                    }}
                    onChange={(e) => {
                        setMessage(e.target.value)
                    }}
                    sx={{
                        backgroundColor: '#c7c3c3'
                    }}
                    InputProps={{
                        endAdornment: 
                            <InputAdornment position='end'>
                                <Send className='ptr' titleAccess='Send message' onClick={() => {
                                    Utils.sendMessageToAssistant({...props, prompt: msg})
                                    setMessage('')
                                }} sx={{fontSize: 30}} />
                            </InputAdornment>
                    }}
                />
            </FormControl>
        </div>
    )
}

export default ChatTextInput