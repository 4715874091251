import React, { useEffect, useRef, useState } from 'react'
import StillWorkingOnThis from './StillWorkingOnThis'
import Separator from './Separator'
import StateUpdators from '../../lib/StateUpdators'
import Utils from '../../lib/Utils'
import DigitalPINSView from './DigitalPINSView'
import ProgressReportView from './ProgressReportView'
import Search from './Search'
import CheckBox from '@mui/material/Checkbox'
import { pink } from '@mui/material/colors'
import Assets from '../../lib/Assets'
import LoadingComponent from './LoadingComponent'


/**
 * Educator Dashboard Actions
 */
const EducatorDashboardActions = {
    ProgressReport: () => <ProgressReport />,
    CurriculumOverviews: () => <CurriculumOverviews />,
    ForTheClassroom: () => <ForTheClassroom />,
    SearchLessons: () => <SearchLessons />,
    PlanAndOrganize: () => <PlanAndOrganize />,
    DigitalPINS: () => <DigitalPINS />,
    AssessmentsAndFeedback: () => <AssessmentsAndFeedback />,
    Welcome: () => <Welcome />,
    StillWorkingOnthis: ({extraRenders = false}) => <StillWorkingOnthis extraRenders={extraRenders} />,
    GetStarted: () => <GetStarted />,
};

const ProgressReport = () => {
    console.log('{ProgressReport}');
    return <ProgressReportView />
}

const CurriculumOverviews = () => {
    console.log('{CurriculumOverviews}');
    return (
        <>
        <ActionFetchContent section='CurriculumOverviews' />
        </>
    )
}

/**
 * ForTheClassroom action
 * @returns 
 */
const ForTheClassroom = () => {
    console.log('{ForTheClassroom}');
    return (
        <>
        <ActionFetchContent section='ForTheClassroom' />
        </>
    )
}

/**
 * The search lessons action
 * @returns 
 */
const SearchLessons = () => {
    console.log('{SearchLessons}');
    const [lessons, setLessonsOnSearch] = useState({
        list: [],
        searchTerm: '',
        searched: false
    });
    const [checked, setChecked] = useState(true);
    StateUpdators.setLessonsOnSearch = setLessonsOnSearch;
    return (
        <>
        <div className='docs-c4k fhfh-edit box-b animate__animatted animate__fadeIn width-90 centred rounded wt-bkg'>
            <Separator styles={{height: '20px'}} />
            <h3 className='ptr' title='Enter a term, then press enter or click the search icon to search'>Search lessons</h3>
            <Separator styles={{height: '20px'}} />
            <div className='jshsh-ndgeg'>
                <Search section='lessons' />
            </div>
            {
                lessons.list.length > 0 &&
                <>
                {
                    (() => {
                        Utils.addAnalytics({
                            page: 'EducatorDashboard',
                            url: window.location.href,
                            title: 'NS.EducatorDashboard.Action.SearchLessons',
                            outcome: `Found ${lessons.list.length} results for search term: ${lessons.searchTerm}`
                        })
                    })()
                }
                <p className='mgn-0 hgsjs'>Results for: <b><u>{lessons.searchTerm}</u></b></p>
                <div className='jfhfd-iense ptr' title='Open links in a new window'>
                    <div>
                        <CheckBox
                            checked={checked}
                            onChange={(e) => {
                                Utils.addAnalytics({
                                    page: 'EducatorDashboard',
                                    url: window.location.href,
                                    title: 'NS.EducatorDashboard.Action.SearchLessons.CheckBoxInteraction',
                                    openInNewTabCheckboxStatus: e.target.checked
                                })
                                if(e.target.checked) {
                                    setChecked(true);
                                } else {
                                    setChecked(false);
                                }
                            }}
                            sx={{
                                color: pink[800],
                                '&.Mui-checked': {
                                    color: pink[600],
                                },
                            }}
                        />
                    </div>
                    <div className='dsdss-pss'>
                        <p className='mgn-0 text-left'>Open links in a new window</p>
                    </div>
                </div>
                {
                    <div className='jshsg-resjsh bdr-bl rounded box-b'>
                    {
                        lessons.list.map((lesson,key) => {
                            const lessonTitle = `${lesson.curriculumTitle} &raquo; ${lesson.courseTitle} &raquo; ${lesson.subCourseTitle?`${lesson.subCourseTitle} &raquo;`:''} ${lesson.lessonTitle}`;
                            const lessonIconURL = `${Utils.assetsURL}${lesson.curriculumExtraInfo.shortName}/icons/lessons/${lesson.lessonIcon}`;
                            const token = {
                                courseId: lesson.courseId,
                                categoryId: lesson.courseCategoryId ? lesson.courseCategoryId : undefined,
                                hasSubSubCourses: lesson.course.hasSubSubCourses ? lesson.course.hasSubSubCourses : undefined,
                                courseTitle: lesson.courseTitle,
                                curriculumTitle: lesson.curriculumTitle,
                                extra: lesson.curriculumExtraInfo
                            };
                            return (
                                <React.Fragment key={key}>
                                    <div className='jshsgdk shadow rounded mgn-around-10 ptr' title='Click to access the lesson' onClick={() => {
                                        Utils.addAnalytics({
                                            page: 'EducatorDashboard',
                                            url: window.location.href,
                                            title: 'NS.EducatorDashboard.Action.SearchLessons.OpenResultsLinks',
                                            openInNewTabCheckboxStatus: checked,
                                            openLink: {lessonId: lesson.lessonId, token: token}
                                        })
                                        if(checked) {
                                            Utils.goToLesson({lessonId: lesson.lessonId, token: token, inNewWindow: true});
                                        } else {
                                            Utils.goToLesson({lessonId: lesson.lessonId, token: token});
                                        }
                                    }}>
                                        <div className='ndhs-iconsss'>
                                            <img alt='Lesson' src={lessonIconURL} />
                                        </div>
                                        <div className='m-keje-ldkdj'>
                                            <h4 className='mgn-0 msjs-ldkd' dangerouslySetInnerHTML={{__html: lessonTitle}}></h4>
                                            <p className='hsgse0-keueh' dangerouslySetInnerHTML={{__html: lesson.intro}}></p>
                                        </div>
                                    </div>
                                </React.Fragment>
                            )
                        })
                    }
                    </div>
                }
                </>
            }
            
            {
                lessons.list.length === 0  && lessons.searched &&
                <>
                <div className='jshs-pejdhs text-centre'>
                    <img alt='Empty' className='dghsgs-hdgd' src={Assets.icons.emptyBox} />
                    <p className='mgn-0 hgsjs'>We could find nothing for: <b><u>{lessons.searchTerm}</u></b></p>
                </div>
                </>
            }
        </div>
        </>
    )
}

/**
 * Fetch digital PINS for the teacher
 * @returns 
 */
const DigitalPINS = () => {
    console.log('{EducatorDashboardActions.DigitalPINS}');
    return <DigitalPINSView />
}

/**
 * When we cannot find an action
 * @returns 
 */
const StillWorkingOnthis = ({extraRenders = false}) => {
    console.log('{EducatorDashboardActions.StillWorkingOnThis}')
    return (
        <>
        <Separator styles={{height: '50px'}} />
        <StillWorkingOnThis ExtraRenders={extraRenders} />
        </>
    )
}

const PlanAndOrganize = () => {
    console.log('{PlanAndOrganize}');
    return (
        <>
        <ActionFetchContent section='PlanAndOrganize' />
        </>
    )
}

/**
 * The welcome action
 * @returns 
 */
const Welcome = () => {
    console.log('{EducatorDashboardActions.Welcome}');
    return (
        <>
        <ActionFetchContent section='Welcome' />
        </>
    )
}

/**
 * The GetStarted action
 * @returns 
 */
const AssessmentsAndFeedback = () => {
    console.log('{EducatorDashboardActions.AssessmentsAndFeedback}');
    return (
        <>
        <ActionFetchContent section='AssessmentsAndFeedback' />
        </>
    )
}

/**
 * The GetStarted action
 * @returns 
 */
const GetStarted = () => {
    console.log('{EducatorDashboardActions.GetStarted}');
    return (
        <>
        <ActionFetchContent section='GetStarted' />
        </>
    )
}

/**
 * The action content fetcher
 * @param {string} section The section to fetch content for
 * @returns 
 */
const ActionFetchContent = ({section}) => {
    console.log('{EducatorDashboardActions.ActionFetchContent}');
    const [Content, setEducatorDashboardActionsContent] = useState({
        C: () => 
        <>
        <Separator styles={{height: '50px'}} />
        <LoadingComponent />
        </>
    });
    const _section = useRef(section);
    StateUpdators.setEducatorDashboardActionsContent = setEducatorDashboardActionsContent;
    useEffect(() => {
        Utils.fetchEducatorDashboardContent({section: _section.current});
    },[]);
    return (
        <>
        <Content.C />
        </>
    )
}

export {Welcome,StillWorkingOnthis,GetStarted,DigitalPINS};

export default EducatorDashboardActions;