import React from 'react'
import Assets from '../../../lib/Assets'
import LoadingComponent from '../LoadingComponent'

/**
 * BotMessage
 * @param {object} props Th props object
 * @returns 
 */
const BotMessage  = (props) => {
    console.log('{BotMessage}')
    return (
        <>
        <div className={`jsjs-pels w-90 grid box-b text-by-${props.response.by}`}>
            <div>
                <img alt='Bot profile' className='sjs-bot-as-pp' src={Assets.icons.aiBot} />
            </div>
            <div>
                <div className='text-centre ksks-bot-sjs box-b'>
                    <span>Thinking...</span>
                    <LoadingComponent />
                </div>
            </div>
        </div>
        </>
    )
}
export default BotMessage