import React, {useEffect, useState } from 'react'
import Separator from './Separator'
import Utils from '../../lib/Utils'
import LoadingComponent from './LoadingComponent'
import EmptyBox from './EmptyBox'
import DataGridHeaders from '../../lib/DataGridHeaders'
import TableActions from './TableActions'
import { DataGrid } from '@mui/x-data-grid'
import NeedHelpRoundPopup from './NeedHelpRoundPopup'

/**
 * Digital PINS (view) for teachers
 * @returns 
 */
const ProgressReportView = () => {
    console.log('{ProgressReportView}')
    const [info,setInfo] = useState({
        students: [],
        fetched: false,
        fetching: true
    })
    const [rowSelectionModel2, setRowSelectionModel2] = useState([])
    useEffect(() => {
        Utils.fetchStudents({updator: setInfo})
    },[]);
    return (
        <>
        <Separator styles={{height: '10px'}} />
        <div className='docs-c4k fhfh-edit box-b animate__animatted animate__fadeIn width-90 centred rounded wt-bkg'>
            {
                info.fetching ?
                <div className='h-100 content-centre'>
                    <LoadingComponent />
                </div> :
                <>
                {
                    info.students.length < 1 ? 
                    <div>
                        <EmptyBox />
                        <p>The institution has no students at the moment</p>
                    </div> :
                    <div className='hfgdg ksks-lls w-90 rounded-4px box-b shadow centred'>
                    <DataGrid 
                        getRowId={row => row.studentId}
                        columns={DataGridHeaders.progressReport}
                        rows={info.students}
                        initialState={{
                            pagination: {
                            paginationModel: {
                                pageSize: 50,
                            },
                            },
                        }}
                        pageSizeOptions={[50, 100]}
                        checkboxSelection
                        disableRowSelectionOnClick
                        onRowSelectionModelChange={(newRowSelectionModel) => {
                            setRowSelectionModel2(newRowSelectionModel)
                        }}
                    />
                    {
                        rowSelectionModel2.length !== 0 &&
                        <>
                        {
                            rowSelectionModel2.length > 1 ?
                            <TableActions.ProgressReport.OnMultipleStudents students={info.students.filter(std => rowSelectionModel2.indexOf(std.studentId) > -1)} />
                            :
                            <TableActions.ProgressReport.OnASingleStudent students={info.students.filter(std => rowSelectionModel2.indexOf(std.studentId) > -1)} />
                        }
                        </>
                    }
                </div>
                }
                </>
            }
        </div>
        <NeedHelpRoundPopup section='Progress Report View' topic='about-howto-generate' />
        </>
    )
}

export default ProgressReportView