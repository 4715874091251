import React, { useEffect } from 'react'
import Utils from '../lib/Utils'
import AbsoluteCOLLogo from './tools/AbsoluteCOLLogo'
import HomePageNavMenu from './tools/HomePageNavMenu'
import MobileNavMenu from './tools/MobileNavMenu'
import SectionOneAboutCOL from './tools/SectionOneAboutCOL'
import QuickHelpView from './tools/QuickHelpView'

const Home = () => {
    console.log('{Home}')
    useEffect(() => {
        Utils.addAnalytics({
            page: 'Home',
            url: window.location.href,
            title: 'NS.HomePageVisit'
        })
        Utils.setPageTitle('Classesonline -> Home')
        return undefined
    })
    return (
        <>
        <div className='c4k-home animate__animated animate__fadeIn'>
            <div className='mshsh-lkdks'>
                <div className='ksjsjs'>
                    <AbsoluteCOLLogo styles={{
                        left: '20px',
                        top: '20px'
                    }} />
                </div>
                <div className='nshsghd-ldkdj-nav'>
                    <HomePageNavMenu page={'home'} />
                </div>
            </div>
            <MobileNavMenu page={'home'} />
            <SectionOneAboutCOL />
        </div>
        <QuickHelpView />
        </>
    )
}

export default Home;