import React from 'react'
import HelpTip from './HelpTip'
import StateUpdators from '../../lib/StateUpdators'
import Utils from '../../lib/Utils'
import LoadingComponent from './LoadingComponent'
import Assets from '../../lib/Assets'
import Separator from './Separator'

/**
 * Peer and Self Assessment Docs View
 * @returns 
 */
const SelfAssessmentDocsView = (props) => {
    console.log('{SelfAssessmentDocsView}')
    const [info, setInfo] = React.useState({
       fetching: true,
       error: false,
       errMsg: '',
       data: []
    })
    StateUpdators.setSelfAssessInfo = setInfo
    React.useEffect(() => {
        Utils.addAnalytics({
            page: 'SelfAssessmentDocsView',
            url: window.location.href,
            title: 'NS.SelfAssessmentDocsViewPageVisit'
        })
        Utils.fetchPeerAssessmentDocs({...props})
    },[props])
    return (
        <div className='sjs-ldksj'>
            <div className='text-centre'>
                <h3 className='mgn-0'>Self and Peer Assessment <HelpTip section='LMSDocs' topic='about-self-and-peer-assessment' /></h3>
            </div>
            <div>
                <Separator style={{height: '50px'}} />
                {
                    info.fetching ? (
                        <div className='text-centre'>
                            <p>Fetching docs...</p>
                            <LoadingComponent />
                        </div>
                    ) : (
                        info.error ? (
                            <div className='text-centre error'>
                                <p>{info.errMsg}</p>
                            </div>
                        ) : (
                            <div className='jshs-llsk flex-content-centred animate__animated animate__fadeIn'>
                                {
                                    info.data.map((item, index) => (
                                        <div key={index} onClick={() => {
                                            Utils.goTo({url: item.url, inNewWindow: true})
                                        }} 
                                            className='jshs-llsk-item rounded shadow ptr'
                                            title={`Download ${item.title}`}
                                        >
                                            <div className='content-centre'>
                                                <img alt='File type' className='doc-typ' src={Assets.fileTyeps[item.type]} />
                                            </div>
                                            <div className='content-centre'>
                                                <h4 className='mgn-0 font-12'>{item.title}</h4>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        )
                    )
                }
            </div>
        </div>
    )
}

export default SelfAssessmentDocsView