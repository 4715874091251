import { Button } from '@mui/material';
import React from 'react';
import Utils from '../../lib/Utils';


const TableActions = {
    //progress report actions
    ProgressReport: {
        /**
         * Full actions view
         * @returns 
         */
        OnMultipleStudents: ({students}) => {
            console.log('{TableActions.ProgressReport.ActionsOnMultipleStudents}');
            return (
                <ActionsOnDataGrid 
                    buttons={[
                        () => 
                            <Button variant='contained' color='primary' className='ptr' title='Generate Progress Report' onClick={() => {
                                Utils.showReportGenerationDateSelectionForm({students: students})
                            }}>
                                Generate Progress Report
                            </Button>
                    ]}
                />
            )
        },
        /**
         * Partial actions view
         * @returns 
         */
        OnASingleStudent: ({students}) => {
            console.log('{TableActions.ProgressReport.ActionsOnMultipleStudents}');
            return (
                <ActionsOnDataGrid 
                    buttons={[
                        () => 
                            <Button variant='contained' color='secondary' className='ptr' title='Generate Progress Report' onClick={() => {
                                Utils.showReportGenerationDateSelectionForm({students: students})
                            }}>
                                Generate Progress Report
                            </Button>
                    ]}
                />
            )
        }
    },
    /**
     * Full actions view
     * @returns 
     */
    ActionsOnMultipleStudents: () => {
        console.log('{TableActions.ActionsOnMultipleStudents}');
        return (
            <ActionsOnMultipleStudents />
        )
    },
    /**
     * Partial actions view
     * @returns 
     */
    ActionsOnASingleStudent: () => {
        console.log('{TableActions.ActionsOnASingleStudent}')
        return (
            <ActionsOnASingleStudent />
        )
    }
};


/**
 * Full actions view. For actions that can be applied to multiple users/students.
 * @param {object} students A students array to perform actions on
 * @returns 
 */
export const ActionsOnMultipleStudents = ({students}) => {
    console.log('{ActionsOnMultipleStudents}');
    return (
        <>
        <div className='nfgd-kfjfhd'>
            <div className='jhdjsjs nhs-left'>
                <h3 className='mgn-0'>Actions:</h3>
            </div>
            <div className='jshsh-jdjhs'>
                <Button variant='contained' color='primary' className='ptr' title='Generate student progress report' onClick={() => {
                    Utils.showReportGenerationDateSelectionForm({students: students})
                }}>
                    Generate Report
                </Button>
            </div>
        </div>
        </>
    )
}

/**
 * Partial actions view. For actions that ONLY can be applied to a single user/student.
 * @param {array} students A students array to perform actions on
 * @returns
 */
export const ActionsOnASingleStudent = ({students}) => {
    console.log('{ActionsOnASingleStudent}')
    return (
        <>
        <div className='nfgd-kfjfhd'>
            <div className='jhdjsjs nhs-left'>
                <h3 className='mgn-0'>Actions:</h3>
            </div>
            <div className='jshsh-jdjhs'>
                <Button variant='contained' color='primary' className='ptr' title='Generate student progress report' onClick={() => {
                    Utils.showReportGenerationDateSelectionForm({students: students})
                }}>
                    Generate Report
                </Button>
            </div>
        </div>
        </>
    )
}


/**
 * Full actions view. For actions that can be applied to rows in a datagrid.
 * @param {array} buttons A students array to perform actions on
 * @returns 
 */
export const ActionsOnDataGrid = ({buttons}) => {
    console.log('{ActionsOnMultipleStudents}');
    return (
        <>
        <div className='nfgd-kfjfhd'>
            <div className='jhdjsjs nhs-left'>
                <h3 className='mgn-0'>Actions:</h3>
            </div>
            <div className='jshsh-jdjhs'>
                {
                    buttons.map((Button, i) => <Button key={i} />)
                }
            </div>
        </div>
        </>
    )
}

export default TableActions