import React, { useEffect } from 'react'
import QuickHelpView from './tools/QuickHelpView'
import MobileNavMenu from './tools/MobileNavMenu'
import HomePageNavMenu from './tools/HomePageNavMenu'
import { useParams } from 'react-router-dom'
import Utils from '../lib/Utils'
import IntegrationHandlers from '../lib/Integrations'
import AbsoluteC4KLogo from './tools/AbsoluteC4KLogo'
import Error from './tools/Error'
import ReportError from './tools/ReportError'
import Loader from './tools/Loader'
import RightSidePopin from './tools/RightSidePopin'


const Integrations = () => {
    console.log('{Integrations}')
    let token = useParams().token
    let IntegrationsHandler = IntegrationHandlers.eMobile.Handler
    if(token) {
        token = JSON.parse(Utils.base64Decode(token))
        IntegrationsHandler = IntegrationHandlers[token.org].Handler
    }
    useEffect(() => {
        Utils.addAnalytics({
            page: 'Integrations',
            url: window.location.href,
            title: 'NS.IntegrationsPageVisit'
        })
        Utils.setPageTitle('Computers 4 Kids | Integrations')
        return undefined
    })
    return (
        <>
        <div className='c4k-home animate__animated animate__fadeIn'>
            <div className='mshsh-lkdks'>
                <div className='ksjsjs'>
                    <AbsoluteC4KLogo styles={{
                        left: '20px',
                        top: '20px'
                    }} />
                </div>
                <div className='nshsghd-ldkdj-nav'>
                    <HomePageNavMenu page={'home'} />
                </div>
            </div>
            <MobileNavMenu page={'home'} />
            <div className='mdjs-pls w-60 rounded box-b shadow centred'>
                <h5>Integrations &raquo; Computers 4 Kids & {IntegrationHandlers[token.org]?.title??IntegrationHandlers.eMobile.title}</h5>
                <div className='mspske-smele'>
                    <IntegrationsHandler />
                </div>
            </div>
        </div>
        <QuickHelpView />
        <Error />
        <ReportError />
        <Loader />
        <RightSidePopin />
        </>
    )
}

export default Integrations