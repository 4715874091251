import React from 'react'
import BaseName from '../../lib/BaseName'
import Utils from '../../lib/Utils'

/**
 * The homepage nav menu
 * @param {object} props The props object
 * @returns 
 */
const HomePageNavMenu = (props) => {
    console.log('{HomePageNavMenu}')
    const loggedIn = Utils.isLoggedIn()
    return (
        <>
        <div className='msksjsk'>
            <div className='mdhs-link-lsjs'>
                <a title='Go to home' href={`/${BaseName.name}`}>Home</a>
            </div>
            <div className='mdhs-link-lsjs'>
                <a title='About Classesonline' target='_blank' rel='noreferrer' href={'https://computers4kids.co.za/about-ict/'}>About</a>
            </div>
            <div className='mdhs-link-lsjs'>
                <a title='Go to Computers 4 Kids' target='_blank' rel='noreferrer' href={'https://computers4kids.co.za/'}>Computers 4 Kids</a>
            </div>
            <div className='mdhs-link-lsjs'>
                <a title='Go to e-Learner' target='_blank' rel='noreferrer' href={'https://e-learner.mobi/'}>e-Learner</a>
            </div>
            <div className='mdhs-link-lsjs'>
                <a title='Contact us' href={`/${BaseName.name}/#get-in-touch`}>Contact</a>
            </div>
            <div className={`mdhs-link-lsjs jh-ldjdj box-b ghs-kej-on-${props.page} ptr`}>
                <a title='Register to get started' href={`/${BaseName.name}/signup`}>Sign up</a>
            </div>
            <div className={`mdhs-link-lsjs ghs-kej-on-${props.page} ptr`}>
                {
                    loggedIn ? 
                    <a title='Access your account' href={Utils.myAccountLink}>Account</a> :
                    <button className='hsgsg-jsjs ptr' title='Login to your account' onClick={() => {
                        Utils.addAnalytics({
                            page: 'Home',
                            url: window.location.href,
                            title: 'NS.LoginForm'
                        });
                        if(!(Utils.isLoggedIn())) {
                            Utils.showLoginForm();
                        } else {
                            window.location = Utils.myAccountLink
                        }
                    }}>Login</button>
                }
            </div>
        </div>
        </>
    )
}

export default HomePageNavMenu