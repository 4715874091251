import React, { useEffect } from 'react';
import Assets from '../../../lib/Assets';
import Utils from '../../../lib/Utils';
import PopupHandleTitle from '../PopupHandleTitle';
import { Download, InfoRounded } from '@mui/icons-material';
import { Button } from '@mui/material';
import Texts from '../../../lib/Texts';

/**
 * MEETemplates
 * @param {object} props The props object
 * @returns 
 */
const MEETemplates = ({lesson}) => {
    console.log('{MEETemplates}');
    //console.log('{MEETemplates} lesson=',lesson);
    const templates = lesson.lesson.templates ?? false;
    const htm5Templates = lesson.lesson.htm5Templates ?? false;
    useEffect(() => {
        Utils.addAnalytics({
            page: 'MEETemplates',
            url: window.location.href,
            title: 'NS.MEETemplates'
        });
    },[]);
    return (
        <>
        <div className='hfgfgf-nvfg-task- w-80 h-centred rounded-small animate__animated animate__fadeIn'>
            <h4 className='jdhd-hfg-i'>{`${lesson.curriculumTitle} > ${lesson.courseTitle} > ${lesson.subCourseTitle?` ${lesson.subCourseTitle} >`:''} ${lesson.lesson.number}. ${lesson.lesson.lessonTitle}`}</h4>
            <PopupHandleTitle
                className={'instructions-jfhdh-jshs-sksks w-40'}
                title={'Minecraft Worlds'}
                onLeftIconClick={() => {
                    Utils.closeLessonTaskPageModal()
                }}
                onRightIconClick={() => {}}
                showMinimizeButton={true}
            />
        </div>
        <div className={`animate__animated animate__fadeIn  vnvhfhfjdgf w-80 centred ${templates.length>8?'many-hdgdg-temps':''}`}>
            {
                templates &&
                <>
                {
                    templates.length > 0 &&
                    <>
                    {
                        templates.map((template, i) => {
                            let fileType;
                            switch(template.type) {
                                case 'XLS':
                                    fileType = 'XLSX';
                                    break;
                                case 'XLSX':
                                    fileType = 'XLSX';
                                    break;
                                case 'DOC':
                                    fileType = 'DOCX';
                                    break;
                                default:
                                    fileType = template.type;
                                    break;
                            }
                            template.name = template.name.split('.')[0];
                            return (
                                <div key={i} title={`Download ${template.name}`} className={`the-docs-nfhgfhfgdgh h-centred`} onClick={() => {
                                    Utils.downloadMCTemplate({
                                        fileName: `${template.name}.${template.fileExt}`,
                                        fileExt: template.fileExt,
                                        fileId: template.fileId,
                                        type: template.type
                                    })
                                }}>
                                    <div className='doc-nfhfh shadow rounded ndhh-mee-kkks pointer'>
                                        <div className='the-icon-nvnfh'>
                                            <img className='icon-doc-mbsidhf h-centred' src={Assets.fileTyeps[fileType]} alt={template.name} />
                                        </div>
                                        <div className='bcgdgd-mjfhf-mshs-l box-b h-centred'>
                                            <div className='content-centre'>
                                                <h3 className={`course-text-color-${lesson.color} text-centre`}>{template.name}</h3>
                                            </div>
                                            <div>
                                                <Button color='primary' variant='contained'>
                                                    <span><Download sx={{fontSize: 20}} />Download ({template.size})</span>
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                    </>
                }
                </>
            }
            {
                htm5Templates &&
                <>
                {
                    htm5Templates.length > 0 &&
                    <>
                    {
                        htm5Templates.map((template, i) => {
                            //console.log('teplate',template);
                            template.name = template.name.replace(`.${template.type.toLocaleLowerCase()}`,'');
                            return (
                                <div key={i} title={`Open ${template.name}`} className='the-docs-nfhgfhfgdgh h-centred' onClick={() => {
                                    window.open(template.path);
                                }}>
                                    <div className='doc-nfhfh shadow rounded pointer'>
                                        <div className='the-icon-nvnfh'>
                                            <img className='icon-doc-mbsidhf h-centred' src={Assets.fileTyeps[template.type]} alt={template.name} />
                                        </div>
                                        <div className='bcgdgd-mjfhf-mshs box-b h-centred'>
                                            <h3 className={`course-text-color-${lesson.color}`}>{template.name}</h3>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                    </>
                }
                </>
            }
            {
                (templates.length < 1 && htm5Templates.length < 1) &&
                <>
                <div className='no-templates'>
                    <img className='empty-nfgdgdg ptr' src={Assets.icons.emptyBox} alt='No worlds' title='No worlds for this lesson' />
                    <h3>This lesson has no worlds.</h3>
                    <div className='jshsh-jdusjks rounded bdr-bl shadow'>
                        <div className='content-centre cndhhjss rounded-left bkg-white'>
                            <InfoRounded sx={{fontSize: 40, color: '#594297'}}></InfoRounded>
                        </div>
                        <div className='content-centre jdjsh-ksk rounded-right'>
                            <p>{Texts.noLessTemplates}</p>
                        </div>
                    </div>
                </div>
                </>
            }
        </div>
        </>
    )
}

export default MEETemplates;