import { Button, FormControl,Radio,RadioGroup,FormLabel, InputLabel, MenuItem, Select, TextField, FormControlLabel, InputAdornment, IconButton } from '@mui/material'
import React, { useEffect, useState,useRef, Suspense } from 'react'
import CharacterTraits from '../../lib/CharacterTraits'
import Separator from './Separator'
import BaseName from '../../lib/BaseName'
import Utils from '../../lib/Utils'
import StateUpdators from '../../lib/StateUpdators'
import Assets from '../../lib/Assets'
import Countries from '../../lib/Countries'
import { MuiOtpInput } from 'mui-one-time-password-input'
import PluggableLoader from './PluggableLoader'
import LoginFormView from './LoginFormView'
import ResetPasswordView from './ResetPasswordViewPart'
import ResetPasswordOTPView from './ResetPasswordOTPView'
import RegisterNewAccountValidateOTPView from './RegisterNewAccountValidateOTPView'
import LoginFormResetPasswordEnterNewPasswordView from './LoginFormResetPasswordEnterNewPasswordView'
import CountriesMUIVersion from '../../lib/CountriesMUIVersion'
import TermsAgreeTickBox from './TermsAgreeTickBox'
import { Add, Close, Visibility, VisibilityOff } from '@mui/icons-material'
import Checkbox from '@mui/material/Checkbox'
import SchoolRegFormView from './SchoolRegFormView'
import LicenseeRegForm from './LicenseeRegForm'
import SupportedLanguages from '../../lib/SupportedLanguages'
import Texts from '../../lib/Texts'
import HelpTip from './HelpTip'
import LoadingComponent from './LoadingComponent'

/**
 * Input forms
 */
const Forms = {
    /**
     * EMobileRenewSubscription form
     */
    EMobileRenewSubscription: () => {
        console.log('{Forms.EMobileRenewSubscription}')
        const Form = React.lazy(() => import('./forms/EMobileRenewSubscription'))
        return (
            <Suspense fallback={<LoadingComponent />}>
                <Form />
            </Suspense>
        )
    },
    /**
     * EMobileAddNewUser form
     */
    EMobileAddNewUser: () => {
        console.log('{Forms.EMobileAddNewUser}')
        const Form = React.lazy(() => import('./forms/EMobileAddNewUser'))
        return (
            <Suspense fallback={<LoadingComponent />}>
                <Form />
            </Suspense>
        )
    },
    /**
     * Student Report DateSelection form
     */
    StudentProgressReportDateSelector: ({students}) => {
        console.log('{Forms.StudentProgressReportDateSelector}')
        const Form = React.lazy(() => import('./forms/StudentProgressReportDateSelector'))
        return (
            <Suspense fallback={<LoadingComponent />}>
                <Form students={students} />
            </Suspense>
        )
    },
    /**
     * Student login merge
     */
    StudentLoginMerge: () => {
        console.log('{Forms.StudentLoginMerge}')
        const Form = React.lazy(() => import('./forms/StudentLoginMerge'))
        return (
            <Suspense fallback={<LoadingComponent />}>
                <Form />
            </Suspense>
        )
    },
    /**
     * User registration forms
     */
    UserRegistrations: {
        /**
         * The licensee
         */
        Licensee: () => {
            console.log('{Forms.UserRegistrations.Licensee}');
            return <LicenseeRegForm />
        },
        /**
         * New school registration
         */
        School: () => {
            console.log('{Forms.UserRegistrations.School}');
            return <SchoolRegFormView />
        },
        /**
         * A new user registration form for a home schooler
         */
        HomeSchooler: () => {
            console.log('{Forms.UserRegistrations.HomeSchool}');
            return (
                <HomeSchoolerRegForm />
            )
        }
    },
    /**
     * The reset password input form
     */
    LoginFormResetPasswordEnterNewPassword: ({challengeId}) => <LoginFormResetPasswordEnterNewPasswordView challengeId={challengeId} />,
    /**
     * The OTP input form
     * @param {number} challengeId The challengeId
     * @returns 
     */
    LoginFormResetPasswordOTPChallenge: ({challengeId}) => <ResetPasswordOTPView challengeId={challengeId} />,
    /**
     * When a user is registering, we need to validate their email address.
     * @param {number} challengeId The challengeId 
     * @returns 
     */
    RegisterNewAccountValidateOTPView: ({challengeId}) => <RegisterNewAccountValidateOTPView challengeId={challengeId} />,
    /**
     * The password reset form
     * @returns 
     */
    LoginFormResetPassword: () => <ResetPasswordView />,
    /**
     * The login form
     * @param {boolean} reLogin Whether to reLogin or not
     * @returns 
     */
    LoginForm: ({reLogin}) => <LoginFormView reLogin={reLogin} />,
    /**
     * Validate the PreTest form
     * @param {number} candidateId The canidate Id 
     * @returns 
     */
    PreTestValidateEmail: ({candidateId}) => <ValidatePreTestEmail candidateId={candidateId} />,
    /**
     * The PreTest form
     * @returns 
     */
    PreTest: () => () => <PreTestForm />,
    /**
     * Change access country form
     * @param {object} data The data object 
     * @returns 
     */
    ChangeAccessCountry: ({data}) => <ChangeAccessCountry data={data} />,
    /**
     * Form to add a student group
     * @returns 
     */
    AddAStudentGroup: () => <AddAStudentGroup />,
    /**
     * Edit a student group
     * @returns 
     */
    EditAStudentGroup: (props) => <EditAStudentGroup {...props} />,
    /**
     * The Update Character traits form
     * @returns React view
     */
    UpdateCharTraits: ({current}) => {
        console.log('{Forms.UpdateCharTraits}');
        return (
            <UpdateCharTraits current={current} />
        )
    }
}

/**
 * The HomeSchoolerRegForm form
 * @returns 
 */
const HomeSchoolerRegForm = () => {
    console.log('{HomeSchoolerRegForm}');
    const [showPassword, setShowPassword] = useState(false);
    const __userTypes = {
        HomeSchooler: 'HomeSchool'
    };
    const _texts = useRef({
        HomeSchooler: 'Awesome! You will join us as an <u>Individual</u>.',
        School: 'Awesome! You will join us as a <u>Schooler</u>.',
        Orginazation: 'Awesome! You will join us as an <u>Organization</u>.',
        Licensee: 'Awesome! You will join us as a <u>Licensee</u>.'
    })
    const [_input, setInput] = useState({
        firstname: '',
        lastname: '',
        email: '',
        password: '',
        password2: ''
    });
    const [error, setError] = useState({
        firstname: false,
        lastname: false,
        email: false,
        password: false,
        password2: false,
        msg: ''
    });
    StateUpdators.setErrorOnHomeSchoolerForm = setError;
    useEffect(() => {
        StateUpdators.setHeader('Now, please tell us who you are:');
        StateUpdators.setHeader1(_texts.current[Utils.chosenOption]??'Awesome!, Let us proceed...!')
    },[]);
    return (
        <>
        <div className='hshs09jdhd box-b animate__animated animate__fadeInRight w-90 white-bkg shadow rounded centred'>
            <div className='ksjs-peoe ptr' title='Close/Dismiss'>
                <Close sx={{height: 60}} onClick={() => {
                    Utils.resetRegView()
                }} />
            </div>
            <div className='ksks-prpslsks w-90 centred'>
                <div className='hoverrable-input hdhdds-slsl-ls'>
                    <Separator style={{height: '10px'}} />
                    <FormControl fullWidth>
                        <TextField
                            id='outlined-basic'
                            label='Firstname'
                            variant='outlined'
                            placeholder='Enter your firstname'
                            error={error.firstname}
                            onFocus={() => {
                                setError({...error,firstname: false})
                            }}
                            value={_input.firstname} onChange={(e) => {
                                setInput({
                                    ..._input,
                                    firstname: e.currentTarget.value
                                })
                            }}
                        />
                        <Separator styles={{height: '20px'}} />
                        <TextField
                            id='outlined-basic'
                            label='Lastname'
                            variant='outlined'
                            placeholder='Enter your lastname'
                            error={error.lastname}
                            onFocus={() => {
                                setError({...error,lastname: false})
                            }}
                            value={_input.lastname} onChange={(e) => {
                                setInput({
                                    ..._input,
                                    lastname: e.currentTarget.value
                                })
                            }}
                        />
                        <Separator styles={{height: '20px'}} />
                        <TextField
                            id='outlined-basic'
                            label='Email Address'
                            variant='outlined'
                            placeholder='Enter your email address'
                            error={error.email}
                            onFocus={() => {
                                setError({...error,email: false})
                            }}
                            onBlur={() => {
                                Utils.checkExistingEmail({email: _input.email})
                            }}
                            value={_input.email} onChange={(e) => {
                                setInput({
                                    ..._input,
                                    email: e.currentTarget.value
                                })
                            }}
                        />
                        {
                            error.email ?
                            <span className='error'>{error.msg}</span> : <></>
                        }
                        <Separator style={{height: '20px'}} />
                        <TextField
                            id='outlined-basic'
                            label='Set Password'
                            variant='outlined'
                            placeholder='Set your password'
                            aria-label='Toggle password visibility'
                            error={error.password}
                            onFocus={() => {
                                setError({...error,password: false})
                            }}
                            type={showPassword ? 'text' : 'password'}
                            value={_input.password} onChange={(e) => {
                                setInput({
                                    ..._input,
                                    password: e.currentTarget.value
                                })
                            }}
                            InputProps={{
                                endAdornment: 
                                <InputAdornment position='end'>
                                    <IconButton onClick={() => {
                                        setShowPassword((show) => !show);
                                    }}>{showPassword ? <VisibilityOff /> : <Visibility />}</IconButton>
                                </InputAdornment>
                            }}
                        />
                        <Separator styles={{height: '20px'}} />
                        <TextField
                            id='outlined-basic'
                            label='Confirm Password'
                            variant='outlined'
                            aria-label='Toggle password visibility'
                            placeholder='Re-enter your password'
                            error={error.password2}
                            type={showPassword ? 'text' : 'password'}
                            onFocus={() => {
                                setError({...error,password2: false})
                            }}
                            value={_input.password2} onChange={(e) => {
                                setInput({
                                    ..._input,
                                    password2: e.currentTarget.value
                                })
                            }}
                            InputProps={{
                                endAdornment: 
                                <InputAdornment position='end'>
                                    <IconButton onClick={() => {
                                        setShowPassword((show) => !show);
                                    }}>{showPassword ? <VisibilityOff /> : <Visibility />}</IconButton>
                                </InputAdornment>
                            }}
                        />
                        <Separator styles={{height: '20px'}} />
                    </FormControl>
                </div>
                
                <Separator styles={{height: '20px'}} />

                <CountriesMUIVersion defaultCountry={{
                    countryId: 155,
                    dialCode: '+27'
                }}  />
                            
                <Separator style={{height: '20px'}} />

                <TermsAgreeTickBox />

                <div className='btn-begin jjfhfhf w-80 centred'>
                    <Button title='Continue' variant='contained' className='force-round-btn' onClick={() => {
                        if(!_input.firstname) {
                            Utils.showFeedbackError({msg: 'Plase enter your firstname'});
                            setError({...error, firstname: true});
                            return;
                        } else {
                            setError({...error, firstname: false});
                        }
                        if(!_input.lastname) {
                            Utils.showFeedbackError({msg: 'Plase enter your lastname'});
                            setError({...error, lastname: true});
                            return;
                        } else {
                            setError({...error, lastname: false});
                        }
                        if(!_input.email) {
                            Utils.showFeedbackError({msg: 'Plase enter your email address'});
                            setError({...error, email: true});
                            return;
                        } else {
                            setError({...error, email: false});
                        }
                        if(!Utils.isValidEmail(_input.email)) {
                            Utils.showFeedbackError({msg: 'Plase enter a valid email address'});
                            setError({...error, email: true});
                            return;
                        } else {
                            setError({...error, email: false});
                        }
                        if(!_input.password) {
                            Utils.showFeedbackError({msg: 'Plase set your password'});
                            setError({...error, password: true});
                            return;
                        } else {
                            setError({...error, password: false});
                        }
                        if(!_input.password2) {
                            Utils.showFeedbackError({msg: 'Plase confirm your password'});
                            setError({...error, password2: true});
                            return;
                        } else {
                            setError({...error, password2: false});
                        }
                        if(_input.password !== _input.password2) {
                            Utils.showFeedbackError({msg: 'Passwords do not match'});
                            setError({...error, password: true});
                            return;
                        } else {
                            setError({...error, password: false});
                        }
                        if(!Utils.registrations.checkedTermsCheckBox) {
                            Utils.showFeedbackError({msg: 'Plase agree to our Terms and Conditions'});
                            StateUpdators.setCheckBoxError(true);
                            return;
                        } else {
                            StateUpdators.setCheckBoxError(false);
                        }
                        Utils.registerNewUser({
                            ..._input, 
                            country: Utils.registrations.country,
                            countryId: Utils.registrations.country.countryId, 
                            userType: __userTypes[Utils.chosenOption]
                        });
                    }}>
                        Continue
                    </Button>
                </div>
            </div>
        </div>
        </>
    )
}

/**
 * Validate the PreTest email form
 * @returns 
 */
const ValidatePreTestEmail = ({candidateId}) => {
    console.log('{ValidatePreTestEmail}');
    const TWO_SECS = 2000;
    const [pin, setPIN] = useState('');
    const [_validating, setValidating] = useState({
        doing: true,
        done: false
    });
    const handleOnChange = (newValue) => {
        setPIN(newValue);
    }
    const validating = useRef(null);
    let timer = useRef(null);
    const handleOnComplete = (value) => {
        if(!validating.current) {
            setValidating({
                doing: false,
                done: false,
            });
            validating.current = true;
            timer = setTimeout(() => {
                Utils.validatePreTestCode({candidateId: candidateId, code: parseInt(value)});
            }, TWO_SECS);
        } else {
            if(_validating.retry??false) {
                setValidating({
                    doing: false,
                    done: false,
                });
                validating.current = true;
                timer = setTimeout(() => {
                    Utils.validatePreTestCode({candidateId: candidateId, code: parseInt(value)});
                }, TWO_SECS);
            }
        }
    }
    const matchIsNumeric = (text) => {
        const isNumber = typeof text === 'number';
        return (isNumber || (text !== '')) && !isNaN(Number(text));
    }
    // eslint-disable-next-line
    const validateChar = (value,index) => {
        return matchIsNumeric(value);
    }
    const [V,setValidationResults] = useState({
        C: () => (<></>)
    });
    const _msg = 'Validating code';
    StateUpdators.setValidationResults = setValidationResults;
    StateUpdators.setValidating = setValidating;
    StateUpdators.setPIN = setPIN;
    useEffect(() => {
        const __timer = timer.current;
        return () => clearTimeout(__timer);
    });
    return (
        <>
        <div className='form-nfhfh-jshs'>
            <img id='form-steps' src={Assets.icons.formSteps[0]} alt='Form Steps' />
            <h2>Validate your email</h2>
            <div className='input-formhdhdg w-100 centred hsdhsgs-so'>
                <div className='w-80 centred'>
                {
                    _validating.doing ? 
                    <>
                    <p>Enter the 6-digit code you received from us:</p>
                    <span className='ndhsh-bs-sure'>Be sure to check the spam box if you can't find the email.</span>
                    <MuiOtpInput
                        autoFocus
                        TextFieldsProps={{ placeholder: '-' }}
                        value={pin} 
                        length={6}
                        onChange={handleOnChange} 
                        onComplete={handleOnComplete}
                        validateChar={validateChar}
                    />
                    </>:
                    _validating.done ?
                    <>
                    <V.C />
                    </> :
                    <div className=' content-centre'>
                        <PluggableLoader inlineCSS={false} title={_msg} show={true} parentWidth={300} parentHeight={80} width={80} />
                        {
                            /**
                             * <p className='text-centre'>Validating code...</p>
                             */
                        }
                    </div>
                }
                </div>
            </div>
        </div>
        </>
    )
}

/**
 * The PreTest form
 * @returns 
 */
const PreTestForm = () => {
    console.log('{PreTestForm}');
    const defaultCountryId = 155;
    const [_input, setInput] = useState({
        firstname: '',
        lastname: '',
        email: '',
    });
    const [country, setSelectedCountryId] = useState({
        countryId: defaultCountryId,
        dialCode: '+27'
    });
    return (
        <>
        {
            <div className='form-nfhfh-jshs'>
                <img id='form-steps' src={Assets.icons.formSteps[0]} alt='Form Steps' />
                <h2>Tell us who you are</h2>
                <div className='input-formhdhdg w-50 centred'>
                    <div className='hoverrable-input'>
                        <p className='bhdh-f-input-label'>Firstname:</p>
                        <input type='text' placeholder='Your firstname' className='input-bcbb' value={_input.firstname} onChange={(e) => {
                            setInput({
                                ..._input,
                                firstname: e.currentTarget.value
                            })
                        }} />
                    </div>
                    <div className='hoverrable-input'>
                        <p className='bhdh-f-input-label'>Lastname:</p>
                        <input type='text' placeholder='Your lastname' className='input-bcbb' value={_input.lastname} onChange={(e) => {
                            setInput({
                                ..._input,
                                lastname: e.currentTarget.value
                            })
                        }} />
                    </div>
                    <div className='hoverrable-input'>
                        <p className='bhdh-f-input-label'>Email:</p>
                        <input type='text' placeholder='Your email' className='input-bcbb' value={_input.email} onChange={(e) => {
                            setInput({
                                ..._input,
                                email: e.currentTarget.value
                            })
                        }} />
                    </div>
                    <div className='hoverrable-input'>
                        <p className='bhdh-f-input-label'>Country:</p>
                        <Countries defaultCountry={country} onChange={setSelectedCountryId}  />
                    </div>
                    <div className='btn-begin jjfhfhf w-80 centred'>
                        <Button title='Continue' variant='contained' className='force-round-btn' onClick={() => {
                            if(!_input.firstname) {
                                Utils.showFeedbackError({msg: 'Plase enter your firstname'});
                                return;
                            }
                            if(!_input.lastname) {
                                Utils.showFeedbackError({msg: 'Plase enter your lastname'});
                                return;
                            }
                            if(!_input.email) {
                                Utils.showFeedbackError({msg: 'Plase enter your email address'});
                                return;
                            }
                            if(!Utils.isValidEmail(_input.email)) {
                                Utils.showFeedbackError({msg: 'Plase enter a valid email address'});
                                return;
                            }
                            Utils.initiatePreTest({..._input,country});
                        }}>
                            Continue
                        </Button>
                    </div>
                </div>
            </div>
        }
        </>
    )
}

/**
 * Change access country form
 * @param {object} data The data object 
 * @returns 
 */
const ChangeAccessCountry = ({data}) => {
    console.log('{Forms.ChangeAccessCountry}');
    const [countryId, setValue] = useState(data.userCountryId);
    return (
        <>
        <div className='hsgs-lsj'>
            <h3>Change access country</h3>
            <div className='mjhsghs-jdhsgh w-60 centred'>
                <FormControl>
                    <FormLabel id='lb-choose-new-country-label'>Choose new access country</FormLabel>
                    <RadioGroup
                        aria-labelledby='lb-choose-new-country-label'
                        name='choose-new-country-label'
                        value={countryId}
                        onChange={(e) => {
                            setValue(e.target.value);
                        }}
                    >
                        {
                            data.countries.map((country,key) => {
                                const Label = () => {
                                    return (
                                        <div className='ss-jdhsl'>
                                            <div className='jhs-lwhs'>
                                                <span>{country.flag}</span>
                                            </div>
                                            <div className='jhs-lwhs'>
                                                <p className='mgn-0 jshs-pwzs'>{country.country}</p>
                                            </div>
                                        </div>
                                    )
                                }
                                return <FormControlLabel key={key} value={country.countryId} control={<Radio />} label={<Label />} />
                            })
                        }
                    </RadioGroup>
                </FormControl>
            </div>
            <div className='shss-msl w-60 centred text-align-right'>
                <Button onClick={() => {
                    Utils.saveAccessCountry({countryId: countryId, handler: 'ChangeAccessCountry'});
                }} title='Save settings' color='primary' className='ptr force-round-btn' variant='contained'>
                    Save
                </Button>
            </div>
        </div>
        </>
    )
}


/**
 * Form to edit a student group
 * @returns 
 */
export const EditAStudentGroup = (props) => {
    console.log('{Forms.EditAStudentGroup}')
    //console.log('{Forms.EditAStudentGroup}: props=',props)
    const [input, setFormInput] = useState({
        title: props.title??'',
        groupeId: props.groupeId??'',
    })
    const [err, setError] = useState({
        msg: '',
        title: false
    })
    return (
        <>
        <div className='hgsf-forsgs'>
            <h2>Edit a student group</h2>
            <div className='fhdgd-forms'>
                <TextField value={input.title}
                    error={err.input}
                    helperText={err.msg}
                    onChange={(e) => {
                        const value = e.target.value
                        if(value) {
                            setError({msg: '', title: false})
                        } else {
                            setError({msg: 'Invalid group name', title: true})
                        }
                        setFormInput({...input,title: value})
                    }} id='outlined-basic' label='Group Name:' variant='outlined' 
                />
            </div>
            <Separator styles={{height: '100px'}} />
            <div className='hdgsg-btn w-40 centred'>
                <Button onClick={() => {
                    if(!input.title) {
                        Utils.showFeedbackError({msg: Texts.invalidGrpName})
                        setError({msg: 'Invalid group name', title: true})
                        return
                    } else {
                        setError({msg: '', title: false})
                    }
                    if(input.title.length > 20) {
                        Utils.showFeedbackError({msg: Texts.groupTooLong})
                        setError({msg: 'Group name too long', title: true})
                        return
                    } else {
                        setError({msg: '', title: false})
                    }
                    Utils.saveStudentGroup({...input})
                }} color='primary' variant='contained' className='ptr' title='Save student group'>
                    Save Group
                </Button>
            </div>
        </div>
        </>
    )
}

/**
 * Form to add a student group
 * @returns 
 */
export const AddAStudentGroup = () => {
    console.log('{Forms.AddAStudentGroup}');
    const [input, setFormInput] = useState({groupTitle: ''})
    const [err, setError] = useState({
        msg: '',
        input: false
    })
    return (
        <>
        <div className='hgsf-forsgs'>
            <h2>Add a student group</h2>
            <div className='fhdgd-forms'>
                <TextField value={input.groupTitle}
                    error={err.input}
                    helperText={err.msg}
                    onChange={(e) => {
                        const value = e.target.value
                        if(value) {
                            setError({msg: '', input: false})
                        } else {
                            setError({msg: 'Group name too long', input: true})
                        }
                        setFormInput({groupTitle: value})
                    }} id='outlined-basic' label='Group Name:' variant='outlined' 
                />
            </div>
            <Separator styles={{height: '100px'}} />
            <div className='hdgsg-btn w-40 centred'>
                <Button onClick={() => {
                    if(!input.groupTitle) {
                        Utils.showFeedbackError({msg: Texts.invalidGrpName})
                        setError({msg: 'Invalid group name', input: true})
                        return
                    } else {
                        setError({msg: '', input: false})
                    }
                    if(input.groupTitle.length > 20) {
                        Utils.showFeedbackError({msg: Texts.groupTooLong})
                        setError({msg: 'Group name too long', input: true})
                        return
                    } else {
                        setError({msg: '', input: false})
                    }
                    Utils.addStudentGroup({...input});
                }} color='primary' variant='contained' className='ptr' title='Add student group'>
                    Add Group
                </Button>
            </div>
        </div>
        </>
    )
}

/**
 * Update language settings
 * @param {object} current The current language settings 
 * @param {array} supportedLanguages The supported languages 
 * @returns 
 */
export const UpdateLanguageSettings = ({current,supportedLanguages}) => {
    console.log('{UpdateLanguageSettings}')
    const [input, setInput] = useState(Utils._settings.language.saved ? {language: Utils.cache.currentLangs} : current)
    const [checked, setChecked] = useState(true)
    Utils.cache.currentLangs = input.language
    return (
        <>
        <div className='jfhdh-hfgdg'>
            <h3>Update Language Settings <HelpTip section='Platform Settings' topic='update-lang-settings' /></h3>
            <h4>Current Languages</h4>
            <div className='w-90 jjs-33 centred content-centre bdr-bl rounded shadow'>
                {
                    input.language.map((lan,i) => {
                        return (
                            <div key={i} className='mdjs-lks rounded-4 bdr-bl animate__animated animate__fadeIn'>
                                <div className='ksjsj-lan text-centre'>
                                    <span className='wht-txt text-centre'>{lan.title}</span>
                                </div>
                                <div className='hs-ke-x-ks ptr'>
                                    <Close className='sjs-epe' sx={{color: 'red'}} onClick={() => {
                                        if(lan.id !== SupportedLanguages.defaultLan.id) {
                                            setInput({
                                                ...input,
                                                language: input.language.filter(_lan => _lan.id !== lan.id)
                                            })
                                        } else {
                                            Utils.showFeedbackError({msg: Texts.errors.defLan})
                                        }
                                    }} titleAccess={`Remove '${lan.title}' from current languages`} />
                                </div>
                            </div>
                        )
                    })
                }
            </div>
            <Separator styles={{height: '25px'}} />
            <h4>Add Supported Languages to your profile.</h4>
            <div className='w-90 jjs-33 centred content-centre bdr-bl rounded shadow'>
                {
                    Object.values(supportedLanguages).map((lan,i) => {
                        return (
                            <div key={i} className='mdjs-lks rounded-4 bdr-bl'>
                                <div className='ksjsj-s-lan text-centre'>
                                    <span className='wht-txt text-centre'>{lan.title}</span>
                                </div>
                                <div className='hs-ke-x-ks kjd ptr'>
                                    <Add sx={{color: 'green'}} onClick={() => {
                                        const langIds = input.language.map(_lan => _lan.id)
                                        if(langIds.indexOf(lan.id) < 0) {
                                            const newLangs = input.language
                                            newLangs.push(lan)
                                            setInput({...input, language: newLangs})
                                        }
                                    }} titleAccess={`Add '${lan.title}' to current languages`} />
                                </div>
                            </div>
                        )
                    })
                }
            </div>
            <Separator styles={{height: '30px'}} />
            <div className='checkbox-container box-b w-90 centred'>
                <div className='bjsj-slskf content-centre'>
                    <Checkbox
                        checked={checked}
                        inputProps={{ 'aria-label': 'controlled' }}
                        onChange={(e) => {
                            setChecked(e.target.checked)
                        }}
                    />
                </div>
                <div className='content-centre'>
                    <p className='mgn-0'>Apply these language settings onto the entire institution.</p><HelpTip section={'Platform Settings'} topic={'settings-scopes'} />
                </div>
            </div>
            <Separator styles={{height: '60px'}} />
            <div className='ndbgd-lkdh-shd w-90 centred'>
                <Button variant='contained' color='primary' className='force-round-btn' title='Save language settings' onClick={() => {
                    const userSettings = {
                        key: 'userLanguageSettings',
                        newValue: input.language.map(lang => lang.id)
                    }
                    const instWideSettings = {
                        key: 'institutionLanguageSettings',
                        newValue: input.language.map(lang => lang.id)
                    }
                    Utils.saveSettings({
                        title: 'PlatformSettings',
                        section: checked ? instWideSettings : userSettings,
                        langScope: checked ? 'institution':'user'
                    })
                }}>
                    Save
                </Button>
            </div>
        </div>
        </>
    )
}

/**
 * The Update Character traits form
 * @returns React view
 */
export const UpdateCharTraits = ({current}) => {
    console.log('{UpdateCharTraits}');
    const [input, setUpdateCharTraitsInput] = useState({
        charTraitSessId: current.charTraitSessId,
        viewOption: current.viewOption??'',
        groupId: current.groupId??''
    })
    return (
        <>
        <div className='jfhdh-hfgdg'>
            <h3>Update Character Traits Settings</h3>
            <FormControl fullWidth>
                <InputLabel id='char-trait-view-select-label'>Character Traits View Option</InputLabel>
                <Select
                    labelId='char-trait-view-select-label'
                    id='char-trait-view-select-label'
                    value={input.viewOption}
                    label='Character Traits View Option'
                    onChange={(e) => {
                        setUpdateCharTraitsInput({
                            ...input,
                            viewOption: e.target.value
                        })
                    }}
                >
                    {
                        Object.entries(CharacterTraits.viewOptions).map((entry,key) => {
                            return (
                                <MenuItem key={key} value={entry[0]}>{entry[1]}</MenuItem>
                            )
                        })
                    }
                </Select>
            </FormControl>
            <Separator styles={{height: '30px'}} />
            <FormControl fullWidth>
                <InputLabel id='char-trait-view-std-grp-select-label'>Applies To Which Class/Group</InputLabel>
                <Select
                    labelId='char-trait-view-std-grp-select-label'
                    id='char-trait-view-std-grp-select-label'
                    value={input.groupId}
                    label='Applies To Which Class/Group'
                    onChange={(e) => {
                        setUpdateCharTraitsInput({
                            ...input,
                            groupId: e.target.value
                        })
                    }}
                >
                    {
                        current.studentGroups.map((group,key) => {
                            return (
                                <MenuItem key={key} value={group.groupId}>{group.title}</MenuItem>
                            )
                        })
                    }
                </Select>
            </FormControl>
            <Separator styles={{height: '30px'}} />
            <div className='jdhhfd-jbdbdb'>
                <span className='mfj-dhdkd'>You can manage student groups <a referrerPolicy='noreferror' target='__blank' href={`/${BaseName.name}/students/groups`}>here &raquo;</a></span>
            </div>
            <Separator styles={{height: '60px'}} />
            <div className='ndbgd-lkdh-shd'>
                <Button variant='contained' color='primary' className='force-round-btn' title='Save' onClick={() => {
                    console.log('input: ',input);
                    Utils.saveCharTraitsSettings({...input});
                }}>
                    Save
                </Button>
            </div>
        </div>
        </>
    )
}

export default Forms;
