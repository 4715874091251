import React, { useEffect, useState } from 'react'
import CloseIcon from './CloseIcon'
import StateUpdators from '../../lib/StateUpdators'
import Utils from '../../lib/Utils'
import '../../css/ai_bot_prompt_input.css'
import Assets from '../../lib/Assets'
import ReactMarkdown from 'react-markdown'
import ChatTextInput from './viewable_bits/ChatTextInput'
import BotMessage from './viewable_bits/BotMessage'
import UserMessage from './viewable_bits/UserMessage'
import BotMessageError from './viewable_bits/BotMessageError'

/**
 * AI bot Prompt input view
 * @param {object} props The orops object
 * @returns 
 */
const AIPromptInput = () => {
    console.log('{AIPromptInput}')
    const [visState, setVisibilityState] = useState(false)
    const [currentMsg, setCurremtMessage] = useState({
        fetching: false,
        fetched: false,
        error: false,
        errMsg: '',
        lessonId: 0,
        prompt: {
            text: '',
            by: 'user'
        },
        response: {
            text: '',
            by: 'ai_system'
        }
    })
    const [msgs, setMessages] = useState([])
    StateUpdators.setCurrentMessage = setCurremtMessage
    StateUpdators.setMessages = setMessages
    StateUpdators.setVisibilityState = setVisibilityState
    //console.log('msgs=',msgs)
    //console.log('{currentMsg.prompt.text}=',currentMsg.prompt.text)
    useEffect(() => {
        Utils.addAnalytics({
            page: 'Instructions',
            url: window.location.href,
            title: 'NS.Instructions.AIPromptInput',
            lessonId: currentMsg.lessonId
        })
    },[currentMsg])
    return (
        <>
        <div className={`ai-bot-prompt-input animate__animated animate__fadeInRight rounded shadow vis-state-${visState}`}>
            <CloseIcon className='jdjh-bot-close' onClose={() => {
                setVisibilityState(false)
            }} />
            <div id='chatbot-jsjsj' className='ksjs-content grid box-b'>
                <div className='msks-bot-hdr grid'>
                    <div>
                        <img alt='AI Assistant' className='kks-bot-jsjsj' src={Assets.icons.aiBot} />
                    </div>
                    <div className='content-centre'>
                        <h3>C4K AI Assistant</h3>
                    </div>
                </div>
                <div className='mdjs-bot-body jjs-ppsmsjs grid shadow'>
                    <div id='chat-hist-msgs' className='msks-epe box-b'>
                    {
                        msgs.map((msg, i) => {
                            //console.log('msg=',msg)
                            return (
                                <React.Fragment key={i}>
                                    <div className={`jsjs-pels-user w-90 grid box-b text-by-${msg.prompt.by}`}>
                                        <div>
                                            <div>{msg.prompt.text}</div>
                                        </div>
                                        <div>
                                            <img alt='Bot profile' className='sjs-bot-as-pp' src={Assets.icons.avatarPlaceHolder} />
                                        </div>
                                    </div>
                                    <div className={`jsjs-pels w-90 grid box-b text-by-${msg.response.by}`}>
                                        <div>
                                            <img alt='Bot profile' className='sjs-bot-as-pp' src={Assets.icons.aiBot} />
                                        </div>
                                        <div>
                                            <div className='animate__animated animate__fadeIn'>
                                                <ReactMarkdown children={msg.response.text} />
                                            </div>
                                        </div>
                                    </div>
                                </React.Fragment>
                            )
                        })
                    }
                    {
                        currentMsg.fetching ? 
                        <>
                        <UserMessage {...currentMsg} />
                        <BotMessage {...currentMsg} />
                        </> : 
                        <>
                        {
                            currentMsg.fetched  && currentMsg.error ?
                            <>
                            <UserMessage {...currentMsg} />
                            <BotMessageError {...currentMsg} />
                            </> : <></>
                        }
                        </>
                    }
                    </div>
                    <div className='djjs-chat-input'>
                        <ChatTextInput lessonId={currentMsg.lessonId} />
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default AIPromptInput